import React, { useState } from 'react'
import { graphql } from 'gatsby'
import tw, { styled } from 'twin.macro'
import logo from '../images/Quantics_Logo.svg'
import axios from 'axios'
import { LocaleContext } from '../components/layout'
import SEO from '../components/SEO/SEO'
import { useForm } from 'react-hook-form'
import { withPreview } from 'gatsby-source-prismic'
import { QForm, LargeSolidTealButton } from '../utils/styles'
import Mail from '../images/Mail.svg'
import Phone from '../images/Phone.svg'
// styles
// markup
const ContactPage = ({ data: { prismicMeeting }, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [requested, setRequested] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const onSubmit = async (data) => {
    setSubmitting(true)
    /*
    const response = await axios.post('/.netlify/functions/meeting', data)
    if (response.data.hasOwnProperty('success') && response.data.success) {
      setRequested(true)
    }
    */
    const formData = new FormData()

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1])
      } else {
        formData.append(key, data[key])
      }
    }
    formData.append('form-name', 'contact')
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setRequested(true))
      .catch((error) => console.log(error));
    setSubmitting(false)
  }
  return (
    <main>
      <SEO
        title={prismicMeeting.data.meta_title}
        desc={prismicMeeting.data.meta_description}
        locale={locale}
      />
      <div tw="container py-40">
        <div tw="grid md:grid-cols-2 items-center">
          <div>
            <h1 tw="text-navy">Start your <br/>project</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: prismicMeeting.data.description.html,
              }}
              tw="text-ocean mt-5 text-xl max-w-md"></div>
            
          </div>
          {requested ? (
            <div tw="text-center">
              <h2 tw="text-navy">{i18n.form.message.headline}</h2>
              <p tw="text-xl text-ocean mt-4">{i18n.form.message.desp}</p>
            </div>
          ) : (
            <div>
              <QForm>
                <form onSubmit={handleSubmit(onSubmit)} tw="flex flex-col" name="contact" data-netlify data-netlify-honeypot="bot-field">
                  <input type="hidden" name="form-name" value="contact" />
                  <input
                    {...register('firstName', { required: true })}
                    placeholder={i18n.form.meeting.first_name}
                    className="q-input"
                  />
                  {errors.firstName && (
                    <span tw="mb-2 text-red">{i18n.form.error.first_name}</span>
                  )}
                  <input
                    {...register('lastName', { required: true })}
                    placeholder={i18n.form.meeting.last_name}
                    className="q-input"
                  />
                  {errors.lastName && (
                    <span tw="mb-2 text-red">{i18n.form.error.last_name}</span>
                  )}
                  <input
                    {...register('workEmail', {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                    placeholder={i18n.form.meeting.work_email}
                    className="q-input"
                  />
                  {errors.workEmail?.type === 'required' && (
                    <span tw="mb-2 text-red">{i18n.form.error.work_email}</span>
                  )}
                  {errors.workEmail?.type === 'pattern' && (
                    <span tw="mb-2 text-red">
                      {i18n.form.error.valid_email}
                    </span>
                  )}
                  <input
                    {...register('companyName', { required: false })}
                    placeholder={i18n.form.meeting.company_name}
                    className="q-input"
                  />
                  <input
                    {...register('jobTitle', { required: false })}
                    placeholder={i18n.form.meeting.job_title}
                    className="q-input"
                  />
                  <textarea
                    {...register('message', { required: false })}
                    placeholder={i18n.form.meeting.message}
                    className="q-textarea"
                  />
                  <LargeSolidTealButton
                    type="submit"
                    tw="relative"
                    className={submitting ? 'button is-loading' : 'button'}
                    disabled={submitting}>
                    {i18n.form.meeting.button}
                  </LargeSolidTealButton>
                  <span tw="mt-6 text-sm">Submit</span>
                </form>
              </QForm>
            </div>
          )}

        
        </div>
      </div>
    </main>
  )
}

export default withPreview(ContactPage)

export const pageQuery = graphql`
  query MeetingQuery($locale: String!) {
    prismicMeeting(lang: { eq: $locale }) {
      data {
        headline {
          text
        }
        meta_description
        meta_title
        phone
        email
        description {
          html
        }
      }
    }
  }
`
